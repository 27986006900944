import { createApp } from "vue";
import { createStore } from "vuex";

import "animate.css";
import "swiper/swiper-bundle.css";
import "@/assets/external/jquery/jquery-3.6.4.min.js";
import "fomantic-ui/dist/semantic";
import "fomantic-ui/dist/semantic.min.css";
import Swal from "sweetalert2";
import axios from "axios";
import "./assets/style/global.css";
import { createHead } from "unhead";
import VuePlyr from "vue-plyr";
import App from "./App.vue";
import navigation from "./components/navigation.vue";
import footer from "./components/footer.vue";
import siteBrand from "./components/partials/siteBrand.vue";
import router from "./router";
import state from "./store";
import vue3GoogleLogin from "vue3-google-login";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue3Tour from 'vue3-tour';
import 'vue3-tour/dist/vue3-tour.css';
import 'nprogress/nprogress.css';

import { loadStripe } from "@stripe/stripe-js";

import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueGtag from "vue-gtag";

import * as Sentry from "@sentry/vue";

import "tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/spellchecker";

// Configure TinyMCE Defaults

import contentUiCss from 'tinymce/skins/ui/oxide/content.inline.min.css?inline';

tinyMCE.overrideDefaults({
  plugins: [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "spellchecker",
  ],
  toolbar:
    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | removeformat | spellchecker",
  toolbar_mode: "floating",
  branding: false,
  spellchecker_rpc_url: `${import.meta.env.VITE_API_URL}/tinymce/spellchecker`,
  content_css: false,
  skin: false,
  content_style: contentUiCss,
  setup: (editor) => {
    editor.on("keyup", triggerSpellcheck);
    editor.on("SpellcheckStart", (event) => {
      spell_check_enabled[editor.id] = true;
    });
    editor.on("SpellcheckEnd", (event) => {
      spell_check_enabled[editor.id] = false;
      tinymce.activeEditor.execCommand("mceSpellCheck");
    });
  },
});

import "tinymce/skins/ui/oxide/skin.min.css";

import editor from "@tinymce/tinymce-vue";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { Loader } from "google-maps";
import tinymce from "tinymce";

import 'animate.css';

// Configure Axios

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.withCredentials = true;

const store = createStore({
  modules: {
    state,
  },
});

axios
  .get("/aaa")
  .then((response) => {
    if (response.status === 200) {
      if (response.data.error === false) {
        if (response.data.user !== null) {
          store.commit("setUser", response.data.user);
          store.commit("setAccountType", response.data.account_type);
          store.commit("setIsContractor", response.data.contractor);
        } else {
          store.commit("setUser", null);
          store.commit("setAccountType", null);
        }
        
        if (typeof response.data.login_type !== "undefined") {
          store.commit("setLoginType", response.data.login_type);
        }

        if (typeof response.data.admin_rights !== "undefined") {
          store.commit("setAdminRights", response.data.admin_rights);
        }
      }
    }
  })
  .catch((error) => {
    Swal.fire({
      title: "There are issues connecting",
      text: "Please check your internet connection and try again.",
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      footer:
        "If you have something urgent you can always reach us at team@chefs2go.com.au or call us at 1300 852 294",
      toast: false,
    });
    console.log("API is offline", error);
  });

const app = createApp(App);

if (import.meta.env.VITE_ENV != "local") { 
  Sentry.init({
    app: app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [import.meta.env.VITE_API_URL],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });
}


app.config.globalProperties.$api_url = import.meta.env.VITE_API_URL;
app.use(store);

app.use(vue3GoogleLogin, {
  clientId:
    import.meta.env.VITE_GOOGLE_CLIENT,
});

const head = createHead();
app.provide("state", state);
app.component("navigation", navigation);
app.component("site-brand", siteBrand);
app.component("site-footer", footer);
app.component("v-select", VueSelect);

app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_RECAPTCHA
});

app.component("tinymce", editor);

app.use(VuePlyr);
app.use(router);
app.use(VueTelInput, {
  mode: "international",
  onlyCountries: ["au"],
  defaultCountry: "au",
  placeholder: "Enter your phone number",
  showDialCode: true,
  showFlag: true,
  dropdownOptions: {
    disabledDialCode: true,
  },
});

if(import.meta.env.VITE_ENV != "local") {
  app.use(VueGtag, {
    config: { id: import.meta.env.VITE_GA },
  });
}

app.use(Vue3Tour);

app.mount("#app");

const loader = new Loader(import.meta.env.VITE_MAPS_API, {
  version: "weekly",
  libraries: ["places"],
});

loader.load();

export const human_readable_filesize = (size) => {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

export const $api_url = import.meta.env.VITE_API_URL;
export const $google_maps_api_key = import.meta.env.VITE_MAPS_API;

loadStripe(import.meta.env.VITE_STRIPE_PUBLIC).then(
  (stripe) => (window.stripe = stripe)
);

export const $stripe = window.stripe;

export const get_profile_image = (id) => {
  return `${import.meta.env.VITE_API_URL}/media/users/profile_image/${id}`;
}
