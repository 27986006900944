import axios from "axios";
import Swal from "sweetalert2";
import { googleLogout } from "vue3-google-login";

const state = {
	user: null,
	loggedIn: false,
	token: null,
	account_type: null,
	state_loaded: false,
	login_type: null,
	admin_rights: {},
	contractor: false,
	flags: {}
};

const mutations = {
	setUser(state, user) {
		state.user = user;
		if (user !== null) {
			state.loggedIn = true;
		}
		state.state_loaded = true;
	},
	setToken(state, token) {
		state.token = token;
		state.state_loaded = true;
	},
	setAccountType(state, account_type) {
		state.account_type = account_type;
		state.state_loaded = true;
	},
	setLoginType(state, login_type) {
		state.login_type = login_type;
		state.state_loaded = true;
	},
	setAdminRights(state, rights) {
		state.admin_rights = rights;
	},
	logout(state) {
		state.user = null;
		state.loggedIn = false;
		state.token = null;
		state.account_type = null;
		state.state_loaded = true;
		state.login_type = null;
		state.admin_rights = {};
		googleLogout();
	},
	overrideLoggedIn(state, value) {
		state.loggedIn = value;
	},
	setIsContractor(state, value) {
		state.contractor = value;
	},
	setFlag(state, {flag: flag, value: value}) {
		state.flags[flag] = value;
	},
};

const getters = {
	user(state) {
		return state.user;
	},
	loggedIn(state) {
		return state.loggedIn;
	},
	token(state) {
		return state.token;
	},
	account_type(state) {
		return state.account_type;
	},
	isEmployer(state) {
		if (state.account_type == "employer") return true;
		return false;
	},
	isSeeker(state) {
		if (state.account_type == "seeker") return true;
		return false;
	},
	isStateLoaded(state) {
		return state.state_loaded;
	},
	login_type(state) {
		return state.login_type;
	},
	isAdmin(state) {
		if (state.login_type == "admin") return true;
		return false;
	},
	getAdminRights(state) {
		return state.admin_rights;
	},
	isContractor(state) {
		return state.contractor;
	},
	getFlags(state) {
		return state.flags;
	}
};

const actions = {
	hasAdminRight(context, right) {
		return new Promise((resolve, reject) => {
			if (context.getters.isAdmin) {
				if (typeof context.getters.getAdminRights[right] !== "undefined") {
					if (context.getters.getAdminRights[right] == true) {
						resolve(true);
						return;
					}
				}
			}
			reject(false);
		});
	},
	updateAccountState(context) {
		return new Promise((resolve, reject) => {
			axios.get("/aaa").then((response) => {
				if (response.status === 200) {
					if (response.data.error === false) {
						if (response.data.user !== null) {
							context.commit("setUser", response.data.user);
							context.commit("setAccountType", response.data.account_type);
							context.commit("setIsContractor", response.data.contractor);
							resolve(response.data);
						}

						if (response.data.user == null) {
							context.commit("setUser", null);
							context.commit("setAccountType", null);
							context.commit("overrideLoggedIn", false);
							context.commit("setIsContractor", false);
						}

						if (typeof response.data.login_type !== "undefined") {
							context.commit("setLoginType", response.data.login_type);
						}

						if (typeof response.data.admin_rights !== "undefined") {
							context.commit("setAdminRights", response.data.admin_rights);
						}
					}
				}
			}).catch((error) => {
				Swal.fire({
					title: "Oops!",
					text: "Something went wrong. Please check your internet connection.",
					icon: "error",
				});

				context.commit("logout");
				console.log("API is unreachable", error);
				reject(error);
			});
		});
	},
	awaitStateLoaded(context) {
		return new Promise((resolve, reject) => {
			let attempts = 0;
			let interval = setInterval(() => {
				attempts++;
				if (attempts > 250) {
					clearInterval(interval);
					reject("[Store] State load timeout");
				}
				if (context.getters.isStateLoaded) {
					clearInterval(interval);
					resolve();
				}
			}, 50);
		});
	},
	effectiveUser(context, user_id) {
		return new Promise((resolve, reject) => {
			var form_data = new FormData();
			form_data.append("id", user_id);
			axios.post("/admin/account/effect", form_data, {
				withCredentials: true,
			}).then((response) => {
				if (response.data.error === false) {
					context.dispatch("updateAccountState").then(() => {
						resolve(response.data);
					}).catch((error) => {
						reject(error);
					});
				} else {
					reject(response.data);
				}
			}).catch((error) => {
				reject(error);
			});
		});
	},
	exitEffectiveUser(context) {
		return new Promise((resolve, reject) => {
			axios.get("/admin/account/effect/exit.json", {
				withCredentials: true,
			}).then((response) => {
				if (response.data.error == false) {
					context.dispatch("updateAccountState");
					resolve();
				} else {
					reject(response.data);
				}
			}).catch((error) => {
				reject(error);
			});
		});
	},
	login(context, credentials) {
		$(".async_loading").show();
		return new Promise((resolve, reject) => {
			if (typeof credentials.type == "undefined") {
				throw new Error("Login type not specified");
			}

			if (credentials.type == "local") {
				if (typeof credentials.captcha == "undefined") {
					throw new Error("Captcha not specified");
				}
				axios.post("/account/login", {
					username: credentials.username,
					password: credentials.password,
					recaptchaToken: credentials.captcha,
				}).then((response) => {
					const data = response.data;
					if (data.error === false) {
						if (data.login_type == "admin") {
							context.dispatch("updateAccountState");
							resolve(data);
							return;
						}
						context.commit("setUser", data.user);
						context.commit("setAccountType", data.account_type);
						resolve(data);
					} else {
						Swal.fire({
							title: "Error",
							text: data.message,
							icon: "error",
							confirmButtonText: "OK",
						});
						reject(data);
					}
				}).catch((error) => {
					if (error.response.status == 401) {
						reject({
							error: true,
							message: "Invalid username or password",
						});
					}

					var message = "Something went wrong. Please check your internet connection.";

					if (typeof error.message !== "undefined") {
						message = error.message;
					}

					reject({
						error: true,
						message,
					});
				});
			} else if (credentials.type == "google") {
				axios.post("/account/login", {
					use_google: true,
					google_token: credentials.token,
				}).then((response) => {
					const data = response.data;
					if (data.error === false) {
						context.commit("setUser", data.user);
						context.commit("setAccountType", data.account_type);
						resolve(data);
					} else {
						Swal.fire({
							title: "Error",
							text: data.message,
							icon: "error",
							confirmButtonText: "OK",
						});
						reject(data);
					}
				}).catch((error) => {
					if (error.response.status == 401) {
						reject({
							error: true,
							message: "Google Sign-in Token Rejected",
						});
					}

					if (error.response.status == 400) {
						if (error.response.data.error == "registration_required") {
							reject({
								error: "registration_required",
								message: "Please register first",
							});
						}
					}

					var message = "Something went wrong. Please check your internet connection.";

					if (typeof error.message !== "undefined") {
						message = error.message;
					}

					reject({
						error: true,
						message,
					});
				});
			}
		}).finally(() => {
			$(".async_loading").hide();
		});
	},
};

export default {
	state,
	mutations,
	getters,
	actions,
};
