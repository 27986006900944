<script>
import { useHead } from 'unhead';
import siteBrand from '@/components/partials/siteBrand.vue';

export default {
  name: 'App',
  mounted: () => {
    useHead({
      title: `${siteBrand.setup().site_brand}`,
      meta: [
        { name: 'description', content: 'Professional Kitchen and Hospitality staff where you need it when you need it.' },
        { name: 'keywords', content: 'staff, chefs, catering, hospitality, functions, corporate, catering, food, service, waiters, waitresses, bar staff, bartenders, chefs, cooks, kitchen hands, kitchen staff, kitchen porters, kitchen assistants' }
      ]
    });
  }
}
</script>

<template>
  <div class="pusher">
    <navigation></navigation>
    <router-view></router-view>
    <site-footer></site-footer>
  </div>

  <div class="async_loading">
    <div class="ui active dimmer">
      <div class="ui text loader">Loading</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.async_loading {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
</style>