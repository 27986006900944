import * as VueRouter from "vue-router";
import store from "@/store";
import Swal from "sweetalert2";
import { watch, nextTick } from "vue";
import NProgress from "nprogress";

const routes = [
	{
		path: "/",
		component: () => import("../views/landing.vue"),
		name: "home",
	},
	{
		path: "/about",
		component: () => import("../views/about.vue"),
	},
	{
		path: "/companies",
		component: () => import("../views/companies.vue"),
	},
	{
		path: "/exhibition",
		component: () => import("../views/expo/exhibit.vue"),
  	},
  	{
    	path: "/exhibition/attend",
    	component: () => import("../views/expo/attend.vue"),
	},
	{
		path: "/contractor",
		component: () => import("../views/contractor.vue"),
	},
	{
		path: "/pricing",
		component: () => import("../views/pricing.vue"),
	},
	{
		path: "/one_time_job",
		component: () => import("../views/one_time_job.vue"),
		name: "one_time_job",
		alias: ["/one_time/job"],
	},
	{
		path: "/blog",
		component: () => import("../views/blog.vue"),
		name: "blog"
	},
	{
		path: "/blog/:slug",
		component: () => import("../views/blog_page.vue"),
		name: "blog_page"
	},
	{
		path: "/jobs",
		component: () => import("../views/jobs.vue"),
		name: "jobs",
		alias: ["/browse/jobs"]
	},
	{
		path: "/jobs/location/:location",
		component: () => import("../views/jobs.vue"),
	},
	{
		path: "/job/:id",
		component: () => import("../views/job.vue"),
	},
	{
		path: "/job/:id/:slug",
		component: () => import("../views/job.vue"),
	},
	{
		path: "/temps",
		component: () => import("../views/temps.vue"),
		alias: ["/staff_on_demand"]
	},
	{
		path: "/temp/:id",
		component: () => import("../views/temp.vue"),
		alias: ["/staff_on_demand/:id"]
	},
	{
		name: "login",
		path: "/login",
		component: () => import("../views/login.vue"),
		alias: ["/signin", "/sign-in", "/sign_in", "/log-in", "/log_in"],
	},
	{
		name: "register",
		path: "/register",
		component: () => import("../views/register.vue"),
		alias: ["/signup", "/sign-up"],
	},
	{
		name: 'recovery',
		path: "/recovery",
		component: () => import("../views/recovery.vue"),
	},
	{
		path: "/admin",
		meta: {
			requiresAdmin: true,
		},
		component: () => import("../views/admin/main.vue"),
		children: [
			{
				name: "admin_dashboard",
				path: "dashboard",
				component: () => import("../views/admin/dashboard.vue"),
				meta: {
					requiresAdmin: true,
				},
			},
			{
				name: "admin_temps",
				path: "temps",
				component: () => import("../views/admin/temps.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_seekers",
				path: "seekers",
				component: () => import("../views/admin/seekers.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_user",
				path: "user/:id",
				component: () => import("../views/admin/user.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_employer",
				path: "employer/:id",
				component: () => import("../views/admin/employer.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_employers",
				path: "employers",
				component: () => import("../views/admin/employers.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_contractor_organisations",
				path: "contractors",
				component: () => import("../views/admin/contractors/organisations.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_contractor_organisation",
				path: "contractor/:id",
				component: () => import("../views/admin/contractors/organisation.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_accounts"
				},
			},
			{
				name: "admin_jobs",
				path: "jobs",
				component: () => import("../views/admin/jobs.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_jobs"
				},
			},
			{
				name: "admin_promos",
				path: "promo_ads",
				component: () => import("../views/admin/promos.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_jobs"
				},
			},
			{
				name: "admin_promo",
				path: "promo_ad/:id",
				component: () => import("../views/admin/promo.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_jobs"
				},
			},
			{
				name: "admin_job",
				path: "job/:id",
				component: () => import("../views/admin/job.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_jobs"
				},
			},
			{
				name: "admin_permissions",
				path: "permissions",
				component: () => import("../views/admin/permissions.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_admins"
				},
			},
			{
				name: "admin_packages",
				path: "packages",
				component: () => import("../views/admin/packages.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "access_stripe"
				},
			},
			{
				name: "admin_package",
				path: "package/:id",
				component: () => import("../views/admin/package.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "access_stripe"
				},
			},
			{
				name: "admin_emails",
				path: "emails",
				component: () => import("../views/admin/emails.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "email_users"
				},
			},
			{
				name: "admin_sms",
				path: "sms",
				component: () => import("../views/admin/sms.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "sms_users"
				},
			},
			{
				name: "admin_audit",
				path: "audit",
				component: () => import("../views/admin/audit.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "manage_admins"
				},
			},
			{
				name: "admin_document",
				path: "document",
				component: () => import("../views/admin/document.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "access_contracts"
				},
			},
			{
				name: "admin_blog",
				path: "blog",
				component: () => import("../views/admin/blog.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "edit_website"
				},
			},
			{
				name: "admin_edit_blog",
				path: "blog/:id",
				component: () => import("../views/admin/blog_post.vue"),
				meta: {
					requiresAdmin: true,
					requiresAdminPermission: "edit_website"
				},
			}
		]
	},
	{
		path: "/account",
		meta: {
			requiresAuth: true,
		},
		component: () => import("../views/account/main.vue"),
		children: [
			{
				name: "dashboard",
				path: "dashboard",
				component: () => import("../views/account/dashboard.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "documents",
				component: () => import("../views/account/documents.vue"),
				alias: [
					'resume'
				],
				meta: {
					account_type: "seeker",
					requiresAuth: true,
				},
			},
			{
				path: "training",
				component: () => import("../views/account/training.vue"),
				meta: {
					account_type: "seeker",
					requiresAuth: true,
				},
			},
			{
				path: "profile",
				component: () => import("../views/account/profile.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "preferences",
				component: () => import("../views/account/preferences.vue"),
				meta: {
					requiresAuth: true,
				},
				alias: ["my-preferences"]
			},
			{
				path: "address",
				component: () => import("../views/account/address.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "security",
				component: () => import("../views/account/security.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "venues",
				component: () => import("../views/account/venues.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "billing",
				component: () => import("../views/account/billing.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "browse/seekers",
				alias: ["catalogue"],
				name: "account_browse_seekers",
				component: () => import("../views/account/seeker_catalogue.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "seeker/:id",
				name: "account_browse_seeker",
				component: () => import("../views/account/seeker.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "applicants",
				name: "account_applicants",
				alias: ["my-applicants"],
				component: () => import("../views/account/applicants.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "applicant/:id",
				component: () => import("../views/account/applicant.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "jobs",
				name: "account_jobs",
				component: () => import("../views/account/jobs.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "job/:id",
				name: "account_job_manage",
				component: () => import("../views/account/job.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "promos",
				name: "account_promos",
				component: () => import("../views/account/promos.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "promo/:id",
				name: "account_promo_manage",
				component: () => import("../views/account/promo.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "resources",
				name: "account_employer_resources",
				component: () => import("../views/account/resources.vue"),
				meta: {
					account_type: "employer",
					requiresAuth: true,
				},
			},
			{
				path: "contractor/organisations",
				name: "account_organisations",
				alias: [
					"contractor/organizations"
				],
				component: () => import("../views/account/contractor/organisations.vue"),
				meta: {
					account_type: "contractor",
					requiresAuth: true,
				},
			},
			{
				path: "contractor/organisation",
				alias:
				[
					"contractor/organization"
				],
				children:
				[
					{
						path: ":id",
						name: "account_organisation",
						component: () => import("../views/account/contractor/organisation.vue"),
						children:
						[
							{
								path: "staff",
								name: "account_organisation_staff",
								component: () => import("../views/account/contractor/modules/staff.vue"),
								alias: [ "" ]
							},
							{
								path: "sms",
								name: "account_organisation_sms",
								component: () => import("../views/account/contractor/modules/sms.vue"),
							},
							{
								path: "email",
								name: "account_organisation_email",
								component: () => import("../views/account/contractor/modules/email.vue"),
							},
							{
								path: "user/:user_id",
								name: "account_organisation_user",
								component: () => import("../views/account/contractor/modules/staff_details.vue"),
							}
						]
					},
				],
				meta: {
					account_type: "contractor",
				}
			}
		],
	},
	{
		path: "/privacy",
		component: () => import("../views/legal/privacy.vue"),
		alias: ["/privacy-policy", "/legal/privacy"],
		name: "privacy",
	},
	{
		path: "/terms",
		component: () => import("../views/legal/terms.vue"),
		alias: ["/terms-of-service", "/legal/terms"],
		name: "terms",
	},
	{
		path: "/:pathMatch(.*)*",
		component: () => import("../views/404.vue"),
	},
];

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	mode: "history",
	routes,
});

router.beforeEach(async (to, from, next) => {
	NProgress.start()

	if (!to.meta.requiresAuth && !to.meta.requiresAdmin) {
		next();
		return;
	}

	if (!store.state.state_loaded) {
		await new Promise((resolve, reject) => {
			// console.log("[Router Guard] Waiting for state to load");
			var load_attempts = 0;
			const state_ready_listener = () => {
				load_attempts++;
				if (store.state.state_loaded) {
					console.log("[Router Guard] State loaded");
					resolve();
					return;
				}
				if (load_attempts > 250) {
					console.error("[Router Guard] State load timeout");
					throw new Error("State load timeout");
				}
				return setTimeout(state_ready_listener, 10);
			}

			if (store.state.state_loaded) {
				resolve();
			}

			state_ready_listener();
		}).catch((err) => {
			console.log("Error loading state", err);
			Swal.fire({
				title: "Error",
				text: "Error connecting to website",
				icon: "error"
			});
			next(false);
			return;
		});
	} else {
		// console.log("[Router Guard] State already loaded");
	}

	if (to.name === "login" && store.getters.loggedIn) {
		next({ name: "dashboard" });
		return false;
	}

	if (to.name == "login" && store.state.loggedIn) {
		next({ name: "dashboard" });
		return;
	}

	if (to.name == "register" && store.state.loggedIn) {
		next({ name: "dashboard" });
		return;
	}

	if (to.meta.requiresAdmin) {
		if (store.state.login_type === "admin") {
			if (typeof to.meta.requiresAdminPermission !== "undefined") {
				if (typeof store.state.admin_rights[to.meta.requiresAdminPermission] !== "undefined") {
					if (store.state.admin_rights[to.meta.requiresAdminPermission] === true) {
						next();
						return;
					} else {
						Swal.fire({
							title: "Access Denied",
							text: "You don't have permission to access this page",
							icon: "error",
							confirmButtonText: "Close",
						});
						next({ name: "admin_dashboard" });
						return;
					}
				} else {
					Swal.fire({
						title: "Access Denied",
						text: "You don't have permission to access this page",
						icon: "error",
						confirmButtonText: "Close",
					});
					next({ name: "admin_dashboard" });
					return;
				}
			} else {
				next();
				return;
			}
		} else {
			next({ name: "login" });
			return;
		}
	}

	if (store.state.loggedIn === false) {
		if (to.name === "login") {
			next();
			return;
		}
		next({ name: "login" });
		return;
	}

	if (to.meta.account_type === "contractor") {
		if (store.state.contractor == false) {
			Swal.fire({
				title: "Sorry",
				text: "You must be a contractor to access this page",
				icon: "error",
				confirmButtonText: "Close",
			});
			next({ name: "dashboard" });
			return;
		} else {
			next();
			return;
		}
	}

	if (to.meta.account_type === "seeker") {
		if (store.state.account_type === "seeker") {
			next();
			return;
		}
		Swal.fire({
			title: "Sorry",
			text: "You must be a job seeker to access this page",
			icon: "error",
			confirmButtonText: "Close",
		});
		next({ name: "dashboard" });
		return;
	}

	if (to.meta.account_type === "employer") {
		if (store.state.account_type === "employer") {
			next();
			return;
		}
		Swal.fire({
			title: "Sorry",
			text: "You must be an employer to access this page",
			icon: "error",
			confirmButtonText: "Close",
		});
		next({ name: "dashboard" });
		return;
	}
	// Handle routes that don't require authentication
	next();
	return;
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
