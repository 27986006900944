<template>

	<div class="ui inverted vertical center aligned segment" id="admin_header" v-if="isAdmin">
		<div class="ui small inverted pointing menu stackable">
			<router-link to="/admin/dashboard" class="item" active-class="active">Dashboard</router-link>
			<router-link to="/admin/seekers" class="item" active-class="active"
				v-if="adminRights.manage_accounts">Seekers</router-link>
			<router-link to="/admin/employers" class="item" active-class="active"
				v-if="adminRights.manage_accounts">Employers</router-link>
			<router-link to="/admin/contractors" class="item" active-class="active"
				v-if="adminRights.manage_accounts">Contractors</router-link>
			<router-link to="/admin/jobs" class="item" active-class="active"
				v-if="adminRights.manage_jobs">Jobs</router-link>
			<router-link to="/admin/temps" class="item" active-class="active"
				v-if="adminRights.manage_accounts">Temps</router-link>
			<router-link to="/admin/permissions" class="item" active-class="active"
				v-if="adminRights.manage_admins">Permissions</router-link>
			<router-link to="/admin/packages" class="item" active-class="active"
				v-if="adminRights.manage_subscriptions">Packages</router-link>
			<router-link to="/admin/emails" class="item" active-class="active"
				v-if="adminRights.email_users">Emails</router-link>
			<router-link to="/admin/sms" class="item" active-class="active" v-if="adminRights.sms_users">SMS
				Messaging</router-link>
			<router-link to="/admin/promo_ads" class="item" active-class="active" v-if="adminRights.edit_website">Promo
				Ads</router-link>
			<router-link to="/admin/audit" class="item" active-class="active"
				v-if="adminRights.manage_admins">Audit</router-link>
			<a class="ui inverted item" @click="logout">Logout</a>
		</div>
	</div>
	<div class="ui inverted vertical center aligned segment" id="master_header">

		<div class="ui container">
			<div class="ui large secondary inverted pointing menu">
				<a href="#" class="toc item">
					<i class="sidebar icon"></i>
				</a>
				<router-link to="/" class="item brand" active-class="active"><site-brand /></router-link>
				<router-link to="/jobs" class="item" active-class="active">Jobs</router-link>
				<router-link to="/temps" class="item" active-class="active">Temps</router-link>
				<router-link to="/about" class="item" active-class="active">About</router-link>
				<router-link to="/companies" class="item" active-class="active">Companies</router-link>
				<router-link to="/contractor" class="item" active-class="active">Contractor</router-link>
				<router-link to="/pricing" class="item" active-class="active" v-if="!isSeeker">Pricing</router-link>
				<router-link to="/blog" class="item" active-class="active">Blog</router-link>
				<div class="right item">
					<div id="not_logged_in_actions" v-if="!loggedIn">
						<a class="ui inverted button item" @click="login_action">Log in</a>
						<router-link to="/register" class="item">
							<a class="ui inverted button">Register</a>
						</router-link>
					</div>
					<div class="logged_in_actions" v-if="loggedIn">
						<div class="ui simple dropdown item">
							<i class="user icon"></i>
							<i class="dropdown icon"></i>
							<div class="menu">
								<router-link to="/account/dashboard" class="item">My Account</router-link>
								<router-link to="/account/preferences" class="item"
									v-if="isSeeker">Preferences</router-link>
								<div class="divider"></div>
								<a href="#" class="item" @click="exitEffectiveUser" v-if="isAdmin && loggedIn">Exit
									Effective User</a>
								<div class="divider"></div>
								<a href="#" class="item" @click="logout">Logout</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<!-- Sidebar Menu Mobile Menu -->
	<div class="ui vertical inverted sidebar menu">
		<router-link to="/" class="item" active-class="active">Home</router-link>
		<router-link to="/jobs" class="item" active-class="active">Jobs</router-link>
		<router-link to="/temps" class="item" active-class="active">Temps</router-link>
		<router-link to="/about" class="item" active-class="active">About</router-link>
		<router-link to="/companies" class="item" active-class="active">Companies</router-link>
		<router-link to="/contractor" class="item" active-class="active">Contractor</router-link>
		<router-link to="/pricing" class="item" active-class="active" v-if="!isSeeker">Pricing</router-link>
		<router-link to="/blog" class="item" active-class="active">Blog</router-link>
		<a href="#" class="item" @click="login_action" v-if="!loggedIn">Log in</a>
		<router-link to="/register" class="item" v-if="!loggedIn">Register</router-link>
		<router-link to="/account/dashboard" class="item" v-if="loggedIn">My Account</router-link>
		<router-link to="/account/preferences" class="item" v-if="loggedIn && isSeeker">Preferences</router-link>
		<a href="#" class="item" @click="logout" v-if="loggedIn">Logout</a>
	</div>

	<div id="login_prompt_html" style="display:none;">
		<form action='?'>
			<input type="email" name="email" placeholder="Email" autocomplete="username" id="swal-login-username"
				class="swal2-input">
			<input type="password" name="password" placeholder="Password" autocomplete="current-password"
				id="swal-login-password" class="swal2-input">
			<br>
			<a href="/recovery">Forgot your password?</a>
		</form>
	</div>
</template>

<script>
import axios from 'axios';
import { onMounted, computed } from 'vue';
import { useStore, mapState } from 'vuex';
import { googleOneTap, googleTokenLogin } from 'vue3-google-login';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
import Swal from 'sweetalert2';
import router from '@/router';

export default {
	name: 'navigation',
	setup() {

		const store = useStore();
		const loggedIn = computed(() => store.getters.loggedIn);
		const isSeeker = computed(() => store.getters.isSeeker);
		const isAdmin = computed(() => store.getters.isAdmin);
		const adminRights = computed(() => store.getters.getAdminRights);

		const { executeRecaptcha } = useReCaptcha();

		const exitEffectiveUser = () => {
			store.dispatch("exitEffectiveUser").then(() => {

				Swal.fire({
					title: "Success",
					text: "You have exited effective user mode.",
					icon: "success"
				});

				if (window.location.pathname.startsWith('/account')) {
					router.push("/admin/dashboard");
				}
			}).catch((err) => {
				console.log(err);
				Swal.fire({
					title: "Error",
					text: "Could not exit effective user mode.",
					icon: "error"
				});
			});
		};

		const login_action = () => {
			Swal.fire({
				title: 'Login',
				html: document.getElementById("login_prompt_html").innerHTML,
				confirmButtonText: 'Login',
				focusConfirm: false,
				showCancelButton: true,
				preConfirm: () => {
					const username = Swal.getPopup().querySelector('#swal-login-username').value
					const password = Swal.getPopup().querySelector('#swal-login-password').value
					if (!username || !password) {
						Swal.showValidationMessage(`Please enter username and password`)
					}
					return { username: username, password: password }
				},
				didOpen: () => {
					const popup = Swal.getPopup();
					var usernameInput = popup.querySelector("#swal-login-username");
					var passwordInput = popup.querySelector("#swal-login-password");
					usernameInput.focus();

					usernameInput.addEventListener("keyup", (e) => {
						if (e.key == "Enter") {
							e.preventDefault();
							passwordInput.focus();
						}
					});

					passwordInput.addEventListener("keyup", (e) => {
						if (e.key == "Enter") {
							e.preventDefault();
							Swal.clickConfirm();
						}
					});

				},
				footer: `<a href="/login" class="ui button secondary" type="button" id="google_sign_in_btn">Login using Google</a>`
			}).then((result) => {
				if (result.isDismissed) {
					return;
				}

				executeRecaptcha("login").then((token) => {
					store.dispatch("login", {
						username: result.value.username,
						password: result.value.password,
						type: "local",
						captcha: token
					}).then((data) => {
						if (window.location.pathname == "/login") {
							router.push("/");
						}
						Swal.fire({
							title: "Success",
							text: "You have been logged in",
							icon: "success"
						});
					}).catch((err) => {
						console.log(err);
						Swal.fire({
							title: "Error",
							text: err.message,
							icon: "error"
						});
					});
				});
			});
		};

		const close_banner = () => {
			$("#top_pop_banner").animate({
				height: "0px"
			}, "slow", () => {
				$("#top_pop_banner").hide();
			});
		};

		onMounted(() => {
			$('.masthead').visibility({
				once: false,
				onBottomPassed: function () {
					$('.fixed.menu').transition('fade in');
				},
				onBottomPassedReverse: function () {
					$('.fixed.menu').transition('fade out');
				}
			});
			$('.ui.sidebar').sidebar('attach events', '.toc.item');

			$(".sidebar").on("click", "a", () => {
				router.push($(this).attr("href"));
			});

			setTimeout(() => {
				var date = new Date("2024-02-13");
				if(date.getTime() > Date.now() && ($(window).width() > 700)) {
					$("#top_pop_banner").show();
					$("#top_pop_banner").animate({
						height: "250px"
					}, "slow");
				}
			}, 500);

			// Google One Tap
			store.dispatch("awaitStateLoaded").then(() => {
				if (!loggedIn.value) {
					googleOneTap({ autoLogin: true }).then((response) => {
						store.dispatch("login", {
							type: "google",
							token: response.credential
						}).then(() => {
							if (window.location.pathname == "/login") {
								router.push("/account/dashboard");
							}
						}).catch((error) => {
							if (error.error == "registration_required") {
								Swal.fire({
									title: "Register",
									text: "You must register before you can login with Google",
									icon: "info",
									confirmButtonText: "Register",
									cancelButtonText: "Close",
									showCancelButton: true
								}).then((result) => {
									if (result.isConfirmed) {
										router.push({ name: "register" });
									}
								});
							}
						});
					}).catch((reason) => {

						var ignore_reasons = [
							"Prompt was skipped, reason for skipping: tap_outside",
							"Prompt was skipped, reason for skipping: user_closed_prompt",
							"Prompt was suppressed by user'. Refer https://developers.google.com/identity/gsi/web/guides/features#exponential_cooldown for more info",
							"Prompt was skipped, reason for skipping: user_cancel"
						];

						if (ignore_reasons.includes(reason)) {
							return;
						}
					});
				}
			});

		});

		return {
			loggedIn,
			isSeeker,
			store,
			login_action,
			isAdmin,
			adminRights,
			exitEffectiveUser,
			close_banner
		}
	},
	methods: {
		logout() {
			axios.get('/account/logout').then(response => {
				if (response.status === 200) {
					this.store.commit('logout');
					if (window.location.pathname.startsWith('/account') || window.location.pathname.startsWith('/admin')) {
						router.push('/');
					}
				} else {
					throw new Error('Unknown Error During Logout');
				}
			}).catch(error => {
				Swal.fire({
					icon: 'error',
					title: 'Something went wrong',
					text: 'An error occurred and we could not log you out. Please try again later.'
				});
			});
		}
	}
}
</script>

<style lang="scss" scoped> 
#top_pop_banner {
	display: none;
	height: 0px;
	max-height: 250px;
	background-image: url(https://cdn.chefs2go.com.au/Food_background.webp);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
#top_pop_banner_image_wrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: unset;
	.ui.image {
		max-height: 300px;
		height: 300px;
		margin-top: -35px;
		max-width: 100%;
		image-rendering: optimizeSpeed;
	}
}
#top_pop_banner_text {
	padding-top: 1em;
	.inverted {
		width: 80%;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.7);
		color: #c82126;
		font-weight: 700;
		font-size: 18px;
	}
	.close {
		position: absolute;
		top: 5px;
		right: 0;
	}
}
#master_header .menu {
 	.brand {
 		text-decoration: none;
 		border-bottom: none;
 		font-size: 1.3em;
 		margin-right: 2.5em;
 		font-weight: 700;
 	}
 }

 #not_logged_in_actions {
 	a {
 		display: inline-block;
 	}
 }

 .hidden.menu {
 	display: none;
 }

 .secondary.pointing.menu .toc.item {
 	display: none;
 }

 @media only screen and (max-width: 700px) {
 	.secondary.pointing.menu .toc.item {
 		display: block;
 	}

	#top_pop_banner {
		#top_pop_banner_text {
			.inverted {
				font-size: 16px;
			}
		}
	}
 }
 </style>