<template>
    {{ site_brand }}
</template>

<script>
export default {
    name: 'siteBrand',
    setup() {
        const site_brand = () => {
            if (window.location.hostname === "chefs2go.local") {
                return "Chefs2Go (DEVELOPER)";
            }
            if (window.location.hostname === "staff-it.com.au") {
                return "Staff IT";
            }
            return "Chefs2Go";
        };

        return {
            site_brand: site_brand()
        };
    }
}
</script>