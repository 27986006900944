<template>
    <div class="ui inverted vertical footer segment">
        <div class="ui container">
            <div class="ui stackable inverted divided equal height stackable grid">
                <div class="three wide column">
                    <div class="ui inverted link list">
                        <router-link class="item" to="/jobs">Jobs</router-link>
                        <router-link class="item" to="/about">About Us</router-link>
                        <router-link class="item" to="/login">Login</router-link>
                        <router-link class="item" to="/register">Register</router-link>
                    </div>
                </div>
                <div class="three wide column">
                    <div class="ui inverted link list">
                        <router-link class="item" to="/one_time_job">One time job posting</router-link>
                        <router-link class="item" to="/pricing">Pricing / Packages</router-link>
                        <router-link class="item" to="/legal/terms">Terms of Use</router-link>
                        <router-link class="item" to="/legal/privacy">Privacy Policy</router-link>
                    </div>
                </div>
                <div class="three wide column">
                    <div class="ui inverted link list">
                        <router-link to="/exhibition" class="item">Exhibition</router-link>
                    </div>
                </div>
                <div class="seven wide column">
                    <h4 class="ui inverted header">{{ site_brand }}</h4>
                    <p>&copy; Copyright 2024. All Rights Reserved. Chef 2 Go PTY LTD.</p>
                    <p>This site is operated by Chef 2 Go Pty Ltd, ACN 602 113 940 using {{ site_brand }} as a trading name.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import siteBrand from '@/components/partials/siteBrand.vue';

export default {
    name: 'site-footer',
    setup() {
        const site_brand = siteBrand.setup().site_brand;

        return {
            site_brand
        };
    }
}
</script>